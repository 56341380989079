import { axiosInstance } from "config/axiosConfig";
import { getUserStore } from "stores/userStore";
import {
  BookmarkType,
  userIdFindForm,
  UserInfoUpdateForm,
  userNewPwForm,
  userPwFindForm,
} from "types/user";

export const getUserInfo = async () => {
  const response = await axiosInstance.get("/user/getUserInfo");
  return response.data;
};

export const updateUserInfo = async (requestBody: UserInfoUpdateForm) => {
  // const response = await axiosInstance.post("/api/user/updateUser", request);
  const response = await axiosInstance.post("/user/updateUser", requestBody);
  return response.data;
};

export const withdraw = async (userId: string) => {
  // const response = await axiosInstance.post("/api/user/deleteUser", request);
  const response = await axiosInstance.get("/user/deleteUser", {
    params: {
      userId,
    },
  });
  return response.data;
};

export const checkIdDuplication = (userId: string) => async () => {
  const response = await axiosInstance.get("/user/isDuplicatedUserId", {
    params: { userId: userId },
  });
  return response.data;
};
export const checkEmailDuplication = (email: string) => async () => {
  const response = await axiosInstance.get("/user/isDuplicatedEmail", {
    params: { userEmail: email },
  });
  return response.data;
};

export const chkPassword = async (secretKey: string, userId: string) => {
  // const response = await axiosInstance.post("/api/user/checkSecretKey", request);

  const response = await axiosInstance.get(`/user/checkSecretKey`, {
    params: {
      secretKey,
      userId,
    },
  });
  return response.data;
};

export const duplEmail = async (email: string) => {
  const response = await axiosInstance.get("/user/isDuplicatedEmail", {
    params: { userEmail: email },
  });
  return response.data;
};

export const insertBookmark = async (
  request: {
    bkmrTypCd: BookmarkType;
    refSeq: string;
  }[],
) => {
  const { user } = getUserStore();
  const { userId } = user;
  const response = await axiosInstance.post(
    "/bkmr/insertBkmr",
    request.map((r) => ({
      ...r,
      userId,
    })),
  );
  return response.data;
};

export const getBookmark =
  (bkmrTypCd: BookmarkType, rows: number, start: number) => async () => {
    const { user } = getUserStore();
    const { userId } = user;
    const response = await axiosInstance.get("/bkmr/getListBkmr", {
      params: {
        userId,
        bkmrTypCd,
        rows,
        start,
      },
    });
    return response.data;
  };

export const deleteBkmr = async (selectList: { bkmrSeq: string }[]) => {
  const response = await axiosInstance.post("/bkmr/deleteBkmr", selectList);
  return response.data;
};

export const authToFindId = (formData: userIdFindForm) => async () => {
  const response = await axiosInstance.get("/user/authToFindId", {
    params: {
      email: formData.userEmail ? true : false,
      phone: formData.userPhone ? true : false,
      userName: formData.userName,
      userEmail: formData.userEmail ?? null,
      userPhone: formData.userPhone ?? null,
    },
  });
  return response.data;
};

export const checkToFindId = (formData: userIdFindForm) => async () => {
  const response = await axiosInstance.get("/user/checkToFindId", {
    params: {
      certKey: formData.confirmNum,
      email: formData.userEmail ? true : false,
      phone: formData.userPhone ? true : false,
      userName: formData.userName,
      userEmail: formData.userEmail ?? null,
      userPhone: formData.userPhone ?? null,
    },
  });
  return response.data;
};

export const authToFindPw = (formData: userPwFindForm) => async () => {
  const response = await axiosInstance.get("/user/authToFindPw", {
    params: {
      email: formData.userEmail ? true : false,
      phone: formData.userPhone ? true : false,
      userId: formData.userId,
      userName: formData.userName,
      userEmail: formData.userEmail ?? null,
      userPhone: formData.userPhone ?? null,
    },
  });
  return response.data;
};

export const checkToFindPw = (formData: userPwFindForm) => async () => {
  const response = await axiosInstance.get("/user/checkToFindPw", {
    params: {
      certKey: formData.confirmNum,
      email: formData.userEmail ? true : false,
      phone: formData.userPhone ? true : false,
      userId: formData.userId,
      userName: formData.userName,
      userEmail: formData.userEmail ?? null,
      userPhone: formData.userPhone ?? null,
    },
  });
  return response.data;
};

export const setNewSecretKey = (formData: userNewPwForm) => async () => {
  const response = await axiosInstance.get("/user/setNewSecretKey", {
    params: {
      certKey: formData.certKey,
      userId: formData.userId,
      secretKey: formData.secretKey,
    },
  });
  return response.data;
};

export const getNaverSignInUrl = async () => {
  const response = await axiosInstance.post("/sns/naver/login");
  return response.data;
};

export const getKakaoSignInUrl = async () => {
  const response = await axiosInstance.post("/sns/kakao/login");
  return response.data;
};
